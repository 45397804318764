.moveAnimate {
    animation: shake 0.5s infinite, move 5s;
}

.logoAnimate {
    animation-name: zoom;
    animation-duration: 1s;
    animation-delay: 0;
    animation-timing-function: linear;
}

.loginBoxAnimation {
    animation-name: fadeLeft;
    animation-duration: 1s;
    animation-delay: 0s;
    animation-timing-function: linear;
}
